@media (min-width: 1200px) and (max-width: 1280px) {

    /* navbar */
    .links {
        left: 70% !important;
    }
}


@media (min-width: 992px) and (max-width: 1280px) {

    /* navbar */
    .links {
        left: 0;
    }

    #about {
        width: 100%;
    }

    .main-aboutcontainer {
        width: 100%;
    }

    .aboutus {
        width: 100%;
    }

    .about-text {
        width: 100%;
    }

    /* NFT section */
    .inline-div {
        width: 25%;
        top: 150px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {

    /* navbar */
    .links {
        left: 0;
    }

    /* Header */
    .title h2 {
        font-size: 50px;
        line-height: 1.2;
    }

    /* vision section */
    #about {
        width: 100%;
        padding: 0;
    }

    .main-aboutcontainer {
        width: 100%;
        padding: 0;
    }

    .aboutus {
        width: 100%;
        padding-top: 50px;
    }

    .leftabout-container {
        width: 100%;
        float: none;
    }

    .about-text {
        width: 100%;
        padding: 0;
    }

    .about-text h2 {
        padding: 40px 40px 0px 40px;
    }

    .about-text p {
        padding: 5px 40px;
    }

    .rightabout-container {
        width: 100%;
        float: none;
    }

    .aboutimg {
        width: 100%;
        margin-left: 0;
    }

    .aboutimg img {
        width: 50%;
    }

    /* NFT section */
    #nfts {
        width: 100%;
    }

    .inline-div {
        width: 25%;
        top: 120px;
    }

    /* tabs section */
    #tabs {
        width: 100%;
    }

    /* Roadmap */
    #roadmap {
        width: 100%;
    }

    /* Reviews section */
    #Reviews {
        width: 100%;
    }

    .video_container video {
        width: 100%;
    }

    /* faq section */
    #faq {
        width: 100%;
    }

    .faqinner_container {
        width: 100%;
    }

    .footerlogo img {
        width: 30%;
    }
}

@media only screen and (max-width: 767px) {

    /* navbar */
    .links {
        left: 0;
    }

    /* Header section */
    .title h2 {
        font-size: 40px;
        line-height: 1.2;
        padding-top: 70px;
    }

    .headercontent {
        width: 100%;
    }

    /* vision section */
    #about {
        width: 100%;
        padding: 0;
    }

    .main-aboutcontainer {
        width: 100%;
        padding: 0;
    }

    .aboutus {
        width: 100%;
        padding-top: 50px;
    }

    .leftabout-container {
        width: 100%;
        float: none;
    }

    .about-text {
        width: 100%;
        padding: 0;
    }

    .about-text h2 {
        padding: 40px 40px 0px 10px;
    }

    .about-text p {
        padding-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .rightabout-container {
        width: 100%;
        float: none;
    }

    .aboutimg {
        width: 100%;
        margin-left: 0;
    }

    .aboutimg img {
        width: 70%;
    }

    /* NFT section */
    #nfts {
        width: 100%;
    }

    .nft-container {
        position: relative;
    }

    .inline-div {
        width: 100%;
        top: 60px;
        display: block;
    }

    .inline-div img {
        margin-bottom: 30px;
    }

    .gradient {
        display: none;
    }

    /* tabs section */
    #tabs {
        width: 100%;
    }

    .tab {
        display: flex;
        flex-direction: column;
    }

    .tablinks {
        margin-bottom: 20px;
    }

    /* Roadmap */
    #roadmap {
        width: 100%;
    }

    .content {
        width: 100%;
        border-bottom: none;
        padding-bottom: 10px;
    }

    .content:nth-child(odd) {
        border-left: none;
        padding-top: 0;
        padding-left: 10px;
    }

    .content:nth-child(even) {
        border-right: none;
        padding-top: 0;
        left: 0;
    }

    /* Reviews section */
    #Reviews {
        width: 100%;
    }

    .video_container video {
        width: 100%;
    }

    /* faq section */
    #faq {
        width: 100%;
    }

    .faqinner_container {
        width: 100%;
    }

    .footerlogo img {
        width: 50%;
    }
}