.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* font */
@font-face {
  font-family: Righteous;
	src: url("../src/fonts/Righteous-Regular.ttf");
}

/* custom scroll bar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #7CDA24; 
  border-radius: 5px;
}


/** css **/
body{
  /*background: #0E0737 !important;*/
  background: black !important;
  overscroll-behavior: auto !important;
}

.body{
  width: 100%;
  height: auto;
  overflow-x: hidden;
  overscroll-behavior: auto !important;
  /*background: url("../src/assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;*/
}

/* Navigation */
#logo-heading{
  /*width: 100%;*/
  width: 100px;
  position: relative;
  left: 8.5%;
}
#logo-heading img{
  width: 100px;
  border-radius: 5px;
}
.nav-items{
  color: white !important;
  padding-right: 40px !important;
}
.navbarBg{
  width: 100%;
  background: url("../src/assets/navbar.png") !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.navbar-collapse{
  background: transparent !important;
  padding: 20px !important;
}
.links{
  position: relative;
  top: 1px;
  left: 70%;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}
.show{
  background-color: black !important;
}
.mbl-title{
  color: white !important;
}
.btn-close{
  color: white !important;
}
#close{
  color: white !important;
}

.offcanvas-header .btn-close{
  background: url("../src/assets/close.png");
  background-size: contain;
}

.navbar-toggler-icon{
  background: url("../src/assets/bar.png") !important;
  background-size: 100% 100% !important;
}
.play-btn button{
  background-color: #7CDA24 ;
  border: #7CDA24 2px solid;
  padding: 5px 15px 6px 15px;
  border-radius: 10px;
  color: white;
  border-radius: 60px;
  font-size: 12px;
}
.play-btn button:hover{
  background-color: black;
  border: black 2px solid;
}
#mobile-link{
  display: none;
  background-color: #7CDA24 ;
  border: #7CDA24 2px solid;
  padding: 10px 30px 10px 30px;
  border-radius: 10px;
  color: white;
  width: 45%;
}

/* Header */
#header{
  width: 100%;
  height: 100vh;
  margin: 0px auto;
  background: url("../src/assets/korean_friendship_christmas.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.main-container{
  width: 100%;
}
.title{
  width: 100%;
  text-align: left;
  padding-top: 30px;
}
.title h2{
  color: white;
  font-size: 68px;
  text-align: left;
  line-height: 1.4;
  font-family: Righteous;
}
.headercontent{
  width: 50%;
  text-align: left;
  color: white;
  padding-top: 20px;
}
.headercontent p{
  color: white;
}
.btn-container{
  width: 100%;
  text-align: left;
  margin-left: -15px;
  padding-top: 20px;
}
.btn{
  display: inline-block;
}
.btn a{
  text-decoration: none;
}
.btn p{
  width: 100%;
  background-color: #7CDA24 ;
  border: #7CDA24 2px solid;
  padding: 5px 15px 6px 15px;
  color: white;
  border-radius: 60px;
  font-size: 12px;
}
.btn p:hover{
  background-color: black;
  border: black 2px solid;
}

/* vision section */
#about{
  width: 100%;
  text-align: center;
  padding: 10px;
}
.main-aboutcontainer{
  width: 100%;
  padding: 100px 50px 10px 50px;
}
.aboutus{
  width: 100%;
}
.leftabout-container{
  width: 50%;
  float: left;
}
.about-text{
  width: 100%;
  padding: 60px 0 0 0;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
}
.about-text h2{
  color: #7CDA24;
  text-align: left;
}
.about-text p{
  color: white;
  text-align: justify;
}
.rightabout-container{
  width: 50%;
  float: right;
}
.aboutimg{
  width: 70%;
  margin-left: 100px;
}
.aboutimg img{
  width: 100%;
}

/* NFT secttion */
#nfts{
  width: 100%;
  height: auto;
}
.container{
  position: relative;
}
.nft-container{
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}
.inline-div{
  display: inline-block;
  width: 25%;
  position: relative;
  top: 250px;
}
.inline-div img{
  width: 90%;
  border-radius: 20px;
}
.gradient{
  z-index: 9;
}
.gradient img{
  width: 30%;
}

/* tabs section */
#tabs{
  width: 100%;
  padding: 100px 50px 50px 50px;
}
.tab {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.tablinks {
  background-color: #1D2C21;
  border: none;
  border-radius: 40px;
  outline: none;
  cursor: pointer;
  padding: 6px 25px;
  transition: background-color 0.3s ease;
  color: white;
  margin: 0 5px; /* Adjust the margin to add space between tab links */
}

.tablinks.active, .tablinks:hover {
  background-color: #7CDA24;
}

.blurb-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Ensure items wrap to the next line when space is insufficient */
}

.blurb {
  width: 300px; /* Adjust width as needed */
  margin: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
}

.blurb img {
  width: 100%;
  height: auto;
}

/* Roadmap */
#roadmap {
  width: 100%;
  height: auto;
  padding: 100px 50px 70px 50px;
}

.roadmap-container {
  width: 100%;
}

.roadmap-title {
  width: 100%;
  text-align: center;
}

.roadmap-title h2 {
  color: white;
  padding-bottom: 20px;
  font-size: 40px;
}

.gradient-bg {
  width: 100%;
  background: url("../src/assets/roadmap-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.roadmap-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  text-align: left; /* Align text to the left */
  padding-bottom: 70px;
  width: 80%; /* Adjust the width as needed */
  max-width: 500px; /* Optional: limit the max width */
  margin: 0 auto; /* Center the content block horizontally */
}

.content{
  border-bottom: 2px dashed white
}
.content:nth-child(odd){
  border-left: 2px dashed white;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  position: relative;
  right: 20px;
  padding-left: 30px;
  padding-top: 10px;
}
.content:nth-child(even){
  border-right: 2px dashed white;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  position: relative;
  left: 10px;
  padding-top: 10px;
  padding-right: 10px;
}
.content:first-child {
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.content:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: none;
}

.content h2 {
  color: #7CDA24;
  margin-top: 20px;
  text-align: left; /* Center the heading */
}
.content h3{
  color: white;
  font-size: 17px;
}
.content p{
  color: white;
  font-size: 15px;
}

.content li {
  color: white;
  text-align: left; /* Ensure text is left-aligned */
}

.para3 {
  /*position: relative;
  top: 25px;*/
}

.roadmap-text {
  text-align: center;
  padding-top: 50px;
}

.roadmap-text h3 {
  color: white;
}

.roadmap-content ul {
  list-style-type: disc; /* This adds the bullet points */
  padding-left: 20px; /* Indent the list items */
}

.roadmap-content ul li {
  margin-bottom: 15px; /* Add some space between list items */
}


/* Reviews section */

#Reviews {
  width: 100%;
  height: auto;
  padding: 100px 50px 70px 50px;
}

.inner_container {
  width: 100%;
}

.Reviews_heading {
  text-align: center;
  padding: 20px;
}

.Reviews_heading h2 {
  color: white;
}

.video_container {
  width: 100%;
  text-align: center;
  position: relative; /* Needed for positioning the play button */
  cursor: pointer;
}

.video_container video {
  width: 80%;
  border-radius: 30px;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; /* Allow clicks to pass through to the video element */
}

.play-button img {
  width: 50px; /* Adjust size as needed */
}

/* Banner section */
#banner{
  width: 100%;
  height: 100vh;
  margin: 30px auto;
  background: url("../src/assets/57.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

/* faq section */
#faq{
  width: 100%;
  height: auto;
  background: linear-gradient(180deg, rgba(4, 117, 83, 0) 0%, #047553 100%);
  padding: 100px 50px 10px 50px;
}
.faqinner_container {
  width: 100%;
}
.faq_heading {
  text-align: center;
  padding: 20px;
}
.faq_heading h2 {
  color: white;
}
.footerlogo{
  width: 100%;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 30px;
}
.footerlogo img{
  width: 15%;
  border-radius: 5px;
}
.footer-text{
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.footer-text h2{
  color: white;
  font-size: 22px;
}
.footer-btn{
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.footer-btn-container{
  display: inline-block;
}
.footer-btn-container a{
  text-decoration: none;
}
.footer-btn-container p{
  width: 100%;
  background-color: #7CDA24 ;
  border: #7CDA24 2px solid;
  padding: 5px 15px 6px 15px;
  color: white;
  border-radius: 60px;
  font-size: 12px;
}
.footer-btn-container p:hover{
  background-color: black;
  border: black 2px solid;
}
.social-icons{
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.social-icons a{
  color: white;
}
.footerCredits{
  width: 100%;
  text-align: center;
}
.footerCredits p{
  color: white;
}